import React from "react"
import LangContext from "@contexts/lang"
import Flex from "@components/globals/Flex"
import Typography from "@components/globals/Typography"
import SEO from "@components/common/seo/seo"
import formatMessage from "@utils/texts"
import CheckCircle from "../images/checkCircle.svg"
import Box from "@components/globals/Box"

export default function thankYou({ location }) {
  const { lang } = React.useContext(LangContext)

  return (
    <>
      <SEO
        title={formatMessage({ id: "thankMessage", lang })}
        location={location}
      />
      <Flex
        alignItems="center"
        borderRadius="4px"
        border="solid 1px #f2f2f2"
        backgroundColor="#ffffff"
        flexDirection="column"
        px={3}
        py={8}
      >
        <Box as={CheckCircle} />
        <Typography mt={2} fontSize="18px" fontWeight="bold" textAlign="center">
          {formatMessage({ id: "thankMessage", lang })}
        </Typography>
        <Typography color="grays.subtitle" mt={1} multiline textAlign="center">
          {formatMessage({ id: "requestSubmitted", lang })}
        </Typography>
        <Typography color="grays.subtitle" mt={1} multiline textAlign="center">
          {formatMessage({ id: "teamMember", lang })}
        </Typography>
      </Flex>
    </>
  )
}
